import React from "react";

const CtaNav = ({ link }) => {
  return (
    <div className="ctanav nav-padding nav-items-md">
      {link && <a href={link.url}>{link.name}</a>}
    </div>
  );
};

export default CtaNav;
