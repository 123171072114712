import React from "react";
import MultilevelNav from "../Common/MultiLevelNav/index.jsx";
import SubNav from "../SubNav/SubNav.jsx";

const MainNav = ({
  data,
  handleMouseEnter,
  handleMouseLeave,
  handleFocus,
  handleBlur,
}) => {
  const items = data.primary;

  return (
    <nav className="mainnav nav-padding" aria-label="Primary" role="navigation">
      <ul className="mainnav--top-items mainnav--desktop nav-items">
        {items.map((item) => {
          return item.children && item.children.length && !item.mobile ? (
            <li
              key={item.id}
              className="has-sub"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <a href={item.url}>{item.name}</a>
              {item.children && <SubNav subItems={item.children} />}
            </li>
          ) : (
            !item.mobile && (
              <li key={item.id}>
                <a href={item.url}>{item.name}</a>
              </li>
            )
          );
        })}
      </ul>

      <MultilevelNav data={items} />
    </nav>
  );
};

export default MainNav;
