import React from "react";
import ReactDom from "react-dom";

import AccordionTabsApp from "./AccordionTabsApp.jsx";

export default function renderAccordionTabs() {
  document.querySelectorAll(".js-accordion-tabs").forEach((mount) => {
    let accordionItems = mount.querySelectorAll(".js-accordion-item");

    let dataModel = [];

    accordionItems.forEach((itemMount) => {
      const model = JSON.parse(itemMount.dataset.model);
      dataModel.push(model);
    });

    const appContainer = document.createElement("div");
    appContainer.classList.add("accordiontabs--app-container");
    mount.appendChild(appContainer);

    ReactDom.render(<AccordionTabsApp items={dataModel} />, appContainer);
  });
}
