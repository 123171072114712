import React from "react";
import { IconClose, IconHamburger, IconSearch } from "../Common/index.js";
import CtaNav from "../CtaNav/CtaNav.jsx";
import MainNav from "../MainNav/MainNav.jsx";
import ToolNav from "../ToolNav/ToolNav.jsx";
import { dictionary, dictionaryKeys } from "../../common/internationalization";

const MobileNav = ({ primaryData, toolData, handleClick, isOpen }) => {
  return (
    <div className="mobilenav">
      <button onClick={handleClick}>
        {isOpen ? <IconClose /> : <IconHamburger />}
      </button>

      <div className={`mobilenav--container ${isOpen ? "open" : ""}`}>
        <div className="search">
          <a href="/search">
            <IconSearch viewBox="0 0 12 13" />
            {dictionary(dictionaryKeys.Search)}
          </a>
        </div>
        <MainNav data={primaryData} />
        <ToolNav data={toolData} />
        <CtaNav link={primaryData.connectLink} />
      </div>
    </div>
  );
};

export default MobileNav;
