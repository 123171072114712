import { useState, useEffect } from "react";

function useTallestNodeValue(ref, selector) {
  const [tallestNodeHeight, setTallestNodeHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const nodeList = ref.current.querySelectorAll(selector);

    let tallestNodeHeight = 0;

    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList[i];
      const nodeHeight = node.getBoundingClientRect().height;

      if (nodeHeight > tallestNodeHeight) {
        tallestNodeHeight = nodeHeight;
      }
    }

    setTallestNodeHeight(tallestNodeHeight);
  }, [ref]);

  return tallestNodeHeight;
}

export default useTallestNodeValue;
