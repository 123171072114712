import React from "react";
import ReactDom from "react-dom";

import VideoPromoApp from "../VideoPromo/VideoPromoApp.jsx";

export default function renderVideoPromo() {
  document.querySelectorAll(".js-video-promo").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<VideoPromoApp {...dataModel} />, mount);
  });
}
