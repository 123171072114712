import React from "react";
import ReactDom from "react-dom";

import ClientQuotesApp from "./ClientQuotesApp.jsx";

export default function renderClientQuotes(ancestor) {
  if (!ancestor) {
    ancestor = document;
  }
  ancestor.querySelectorAll(".js-client-quotes-mount").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<ClientQuotesApp {...dataModel} />, mount);
  });
}
