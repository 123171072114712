import { useEffect, useState } from "react";

function useMediaQuery(screenSize) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${screenSize}px)`);
    setMatches(mediaQuery.matches);

    const handler = () => setMatches(mediaQuery.matches);
    mediaQuery.addListener(handler);

    return () => mediaQuery.removeListener(handler);
  }, [screenSize]);

  return matches;
}

export default useMediaQuery;
