/**
 * Dictionary mapping
 *
 * Example entry:
 * BackToPeople: { key: "/Associate Bios/Back to People", default: "Back to People" }
 *
 * The title should be a friendly codename for the dictionary entry,
 * the key should be the path of the dictionary entry item in Sitecore
 * (not including the dictionary item path)
 * and the default should be a default value to show in Storybook or
 * if the dictionary is non-functional.
 */
export const dictionaryKeys = {
  BackToPeople: {
    key: "/Associate Bios/Back to People",
    default: "Back to People",
  },

  IconClose: { key: "/Icons/Close", default: "Close" },
  IconEmail: { key: "/Icons/Email", default: "Email" },
  IconLanguage: { key: "/Icons/Language", default: "Language" },
  IconMenu: { key: "/Icons/Menu", default: "Menu" },
  IconPlay: { key: "/Icons/Play", default: "Play" },
  IconSearch: { key: "/Icons/Search", default: "Search" },
  IconTool: { key: "/Icons/Tool", default: "Tool" },
  IconVideo: { key: "/Icons/Video", default: "Video" },

  Search: { key: "/Header/Search", default: "Search" },
};

const dictionaryHashmap = getFullDictionary();

function getFullDictionary() {
  var dataDict = document.body.getAttribute("data-dictionary");

  if (!dataDict || dataDict.length < 5) {
    console.error(
      "If on live site, dictionary attribute likely not configured correctly. Value: ",
      dataDict,
    );
    return {};
  }

  try {
    return JSON.parse(dataDict).entries;
  } catch (error) {
    console.error(error);
    return {};
  }
}

/**
 *dictionaryKey should be a property of dictionaryKeys.
 *e.g. dictionaryKeys.GoBack
 */
export function dictionary(dictionaryKey) {
  if (!dictionaryKey || !dictionaryKey.key || !dictionaryKey.default) {
    console.error("Dictionary key not configured properly: ", dictionaryKey);
  }

  var dictionaryEntry = dictionaryHashmap[dictionaryKey.key];

  if (!dictionaryEntry) {
    return dictionaryKey.default;
  }

  return dictionaryEntry;
}

/**
 * Sample usage in a component:
 *
 * const labels = {
 *   search: dictionary(dictionaryKeys.BackToPeople)
 * };
 *
 * return <p>{labels.search}</p>;
 */
