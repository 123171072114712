import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { IconArrowSmall } from "../Common";

const AccordionCarousel = ({ slides }) => {

  const carousel = useRef();
  const next = (e) => {
    e.preventDefault();
    carousel?.current?.slickNext();
  };
  const prev = (e) => {
    e.preventDefault();
    carousel?.current?.slickPrev();
  };

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: slides.perPage,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          initialSlide: 0,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getShowArrows = () => {
    return ((window.innerWidth < 740 && slides.items.length > 1) || (window.innerWidth < 980 && slides.items.length > 3) || slides.items.length > 5);
  };

  const [windowWidth, setWindowWidth] = useState(0);
  const [showArrows, setShowArrows] = useState(getShowArrows());

  useEffect(() => {
    function handleResize() {
      const show = getShowArrows();
      console.log(show);
      setShowArrows(show);
    }

    window.addEventListener('resize', handleResize)
  })

  const slidesClass = "accordion-slides--inner" + (showArrows ? "" : " full-width" );
  const slideClass = "c-card c-card--large " + slides.slideClass;

  return (
    <section>
      <div className="accordion-slides">
        {slides.title && (
          <div className="accordion-slides--title">  
            <p>{slides.title}</p>
          </div>
        )}
        <div className={slidesClass}>
          <Slider {...carouselSettings} ref={carousel}>
            {slides.items.map(
              (item) =>
                item.url && (
                  <div key={item.name} className="swiper-slide">

                    <article className={slideClass}>
                        <a href={item.url}>
                            {item.image && (
                              <div className="img">
                                  <img src={item.image} alt={item.imageAlt} />
                              </div>
                            )}

                            <div class="c-card__body">
                                <div class="c-card__content">
                                    {item.name &&
                                      <h3 class="c-card__title u-h5">{item.name}</h3>
                                    }
                                    {item.line1 &&
                                      <h3 class="c-card__title u-h5">{item.line1}</h3>
                                    }
                                    {item.line2 &&
                                      <p class="c-card__title u-h5">{item.line2}</p>
                                    }
                                    {item.line3 &&
                                      <p class="c-card__title u-h5">{item.line3}</p>
                                    }
                                    {item.blueText && (
                                      <p className="u-color--secondary uppercase">{item.blueText}</p>
                                    )}
                                </div>
                            </div>
                        </a>
                    </article>
                  </div>
                )
            )}
          </Slider>
          {showArrows && (
            <div className="accordion-slides--nav">
              <a className="prev" href="#" onClick={prev}>
                <IconArrowSmall direction="left" />
              </a>
              <a className="next" href="#" onClick={next}>
                <IconArrowSmall direction="right" />
              </a>
            </div>
          )}
        </div>       
      </div>
    </section>
  );
};

export default AccordionCarousel;
