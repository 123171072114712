import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { IconArrowSmall } from "../Common/";

const TabbedTextApp = ({ tabs }) => {
  return (
    <section>
      <div className="tabbedtext">
        {tabs.image && (
          <div className="tabbedtext--image">
            <img src={tabs.image} alt={tabs.alt} />
          </div>
        )}

        <Tabs className="tabbedtext--tabs">
          <TabList>
            {tabs.items.map((tab) => (
              <Tab key={tab.title}>{tab.title}</Tab>
            ))}
          </TabList>
          <div className="tabbedtext--tab">
            {tabs.items.map((tab) => (
              <TabPanel key={tab.text}>
                <span className="overline">{tab.tag}</span>
                <p className="body-lg vertical-md">{tab.text}</p>
                {tab.link && (
                  <a href={tab.link}>
                    <IconArrowSmall viewBox="0 0 53 14" />
                  </a>
                )}
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </section>
  );
};

export default TabbedTextApp;
