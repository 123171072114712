import "./polyfill";

import renderPrimaryNav from "./components/PrimaryNav/renderPrimaryNav.jsx";
import renderTabbedText from "./components/TabbedText/renderTabbedText.jsx";
import renderClientQuotes from "./components/ClientQuotes/renderClientQuotes.jsx";
import renderFeatureCarousel from "./components/FeatureCarousel/renderFeatureCarousel.jsx";
import renderVideoPlayer from "./components/VideoPlayer/renderVideoPlayer.jsx";
import renderContentAccordion from "./components/ContentAccordion/renderContentAccordion.jsx";
import renderOfficeLocations from "./components/LocationsMap/renderLocationsMap.jsx";
import renderContactMap from "./components/ContactMap/renderContactMap.jsx";
import renderVideoPromo from "./components/VideoPromo/renderVideoPromo.jsx";
import renderAccordionTabs from "./components/AccordionTabs/renderAccordionTabs.jsx";
import renderAccordionCarousel from "./components/AccordionCarousel/renderAccordionCarousel.jsx"

document.addEventListener("DOMContentLoaded", () => {
  // JS apps
  renderAccordionTabs();
  renderPrimaryNav();
  renderTabbedText();
  renderClientQuotes();
  renderAccordionCarousel();
  renderFeatureCarousel();
  renderVideoPlayer();
  renderContentAccordion();
  renderOfficeLocations();
  renderContactMap();
  renderVideoPromo();
});

//render anything that may not have HTML added to it on DOMContentLoaded.
//can be limited to the ancestor that such components will show up under.
export default function lateRender(ancestor) {
  if (!ancestor) {
    ancestor = document;
  }

  renderClientQuotes(ancestor);
  renderAccordionCarousel(ancestor);
}
