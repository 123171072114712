import React, { useRef } from "react";
import Slider from "react-slick";
import { IconArrowSmall } from "../Common";

const FeatureCarousel = ({ features }) => {
  const carousel = useRef();
  const next = (e) => {
    e.preventDefault();
    carousel?.current?.slickNext();
  };
  const prev = (e) => {
    e.preventDefault();
    carousel?.current?.slickPrev();
  };

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 980,
        settings: {
          initialSlide: 0,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section>
      <div className="featurecarousel">
        <div className="featurecarousel--hero--wrapper">
          <img src={features.background} alt={features.backgroundAlt} />
          <div className="featurecarousel--hero">
            <div className="featurecarousel--title">
              <h2 className="h1">{features.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: features.subTitle }}></p>
            </div>
            {features.items.length > 1 && (
              <div className="featurecarousel--nav">
                <a href="#" onClick={prev}>
                  <IconArrowSmall direction="left" />
                </a>
                <a href="#" onClick={next}>
                  <IconArrowSmall direction="right" />
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="featurecarousel--carousel">
          <Slider {...carouselSettings} ref={carousel}>
            {features.items.map((item) => (
              <div key={item.title} className="featurecarousel--item">
                <img src={item.image} alt={item.alt} />
                <a href={item.link}>
                  <h3 className="h3-alt-lg">{item.title}</h3>
                </a>
                <span className="overline-alt">{item.tag}</span>
                <p>{item.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default FeatureCarousel;
