import React from "react";
import ReactDom from "react-dom";

import ContentAccordion from "./ContentAccordionApp.jsx";

export default function renderTabbedText() {
  document.querySelectorAll(".js-content-accordion").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<ContentAccordion {...dataModel} />, mount);
  });
}
