import React, { useState } from "react";
import ReactPlayer from "react-player";
import { IconPlay } from "../Common";

const VideoPlayer = ({ videoData }) => {
  const [playing, setPlaying] = useState(false);

  const handleClick = () => {
    setPlaying(!playing);
  };

  return (
    <div className="videoplayer o-ratio o-ratio--16:9">
      <ReactPlayer
        url={videoData.url}
        playing={playing}
        controls={true}
        className="videoplayer--player"
        onPause={() => setPlaying(false)}
        width="100%"
        height="100%"
      />
      {videoData.image && (
        <div
          className={`videoplayer--overlay ${playing ? "hide" : ""}`}
          onClick={handleClick}
        >
          <img src={videoData.image} alt={videoData.alt} />
          <button className="videoplayer--button">
            <IconPlay width="120px" height="118px" />
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
