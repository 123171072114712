import React, { useRef } from "react";
import Slider from "react-slick";
import { IconArrowSmall } from "../Common";

const ClientQuotes = ({ quotes }) => {
  const carousel = useRef();
  const next = (e) => {
    e.preventDefault();
    carousel?.current?.slickNext();
  };
  const prev = (e) => {
    e.preventDefault();
    carousel?.current?.slickPrev();
  };

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
  };

  const quotesClass = "clientquotes--quotes" + (quotes.title ? "" : " full-width" );

  return (
    <section>
      <div className="clientquotes">
        {quotes.title && (
          <div className="clientquotes--title">
            <h2 className="h1">{quotes.title}</h2>
          </div>
        )}
        <div className={quotesClass}>
          <Slider {...carouselSettings} ref={carousel}>
            {quotes.items.map(
              (item) =>
                item.text && (
                  <div key={item.text} className="clientquotes--quote">
                    <div className="client-quotes--inner">
                      <div className="quote-body">
                        <p>{item.text}</p>
                        {item.logo && <img src={item.logo} alt={item.alt} />}

                        <p className="body-sm snug">
                          <span>{item.name}</span>{" "}
                          {item.name && item.jobTitle ? "|" : ""}{" "}
                          <span>{item.jobTitle}</span>
                        </p>
                        <p className="body-sm snug">{item.company}</p>
                      </div>
                      {item.image && (
                        <div className="quote-img">
                            <img src={item.image} alt={item.imageAlt} />
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </Slider>
          {quotes.items.length > 1 && (
            <div className="clientquotes--nav">
              <a href="#" onClick={prev}>
                <IconArrowSmall direction="left" />
              </a>
              <a href="#" onClick={next}>
                <IconArrowSmall direction="right" />
              </a>
            </div>
          )}
        </div>       
      </div>
    </section>
  );
};

export default ClientQuotes;
