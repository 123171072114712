import React, { useState } from "react";
import { IconArrowSmall, IconLanguage, IconTool } from "../index.js";
import NavPromo from "../../NavPromo/NavPromo.jsx";

const MultilevelNav = ({ data }) => {
  const [currentMenus, setCurrentMenus] = useState(data);
  const [previousStack, setPreviousStack] = useState([]);
  const [currentParent, setCurrentParent] = useState({});

  const renderMenuItems = (data) => {
    return data.map((item, index) =>
      item?.children && item?.children.length && !(item.mobile === false) ? (
        <li
          className="parent"
          key={index}
          onClick={(e) => {
            e.preventDefault();
            previousStack.push(data);
            setPreviousStack(previousStack);
            setCurrentMenus(item.children);
            setCurrentParent(item);
          }}
        >
          <a href="#">
            {
              {
                lang: <IconLanguage />,
                tool: <IconTool />,
              }[item.icon]
            }
            {item.name} <IconArrowSmall />
          </a>
        </li>
      ) : (
        item.name &&
        !(item.mobile === false) && (
          <li key={index} className={previousStack.length ? "children" : ""}>
            <a href={item.url}>
              {item.name}
              {item.text && <p>{item.text}</p>}
            </a>
          </li>
        )
      )
    );
  };

  return (
    data && (
      <>
        <ul className={`multilevel-nav ${previousStack.length && "has-sub"}`}>
          {previousStack.length ? (
            <li
              onClick={(e) => {
                const prevState = previousStack.pop();
                setPreviousStack(previousStack);
                setCurrentMenus(prevState);
              }}
            >
              <a className="back-nav" href="#">
                <IconArrowSmall direction="left" />
                {
                  {
                    lang: <IconLanguage />,
                    tool: <IconTool />,
                  }[currentParent.icon]
                }
                {currentParent.name}
              </a>
            </li>
          ) : null}
          {renderMenuItems(currentMenus)}
          {currentMenus.length && currentMenus[0].promoData && (
            <NavPromo data={currentMenus[0].promoData} />
          )}
        </ul>
      </>
    )
  );
};
export default MultilevelNav;
