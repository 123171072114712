import React from "react";
import ReactDom from "react-dom";

import ContactMapApp from "./ContactMapApp.jsx";

export default function renderContactMap() {
  document.querySelectorAll(".js-contact-map").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<ContactMapApp {...dataModel} />, mount);
  });
}
