import React from "react";

const Logo = ({ logo }) => {
  return (
    <div className="logo nav-padding">
      {logo && (
        <a href="/">
          <img src={logo.url} alt={logo.alt} />
        </a>
      )}
    </div>
  );
};

export default Logo;
