import React from "react";
import { IconLanguage, IconSearch, IconTool } from "../Common/index.js";
import MultilevelNav from "../Common/MultiLevelNav/index.jsx";
import { dictionary, dictionaryKeys } from "../../common/internationalization";

const ToolNav = ({ data }) => {
  return (
    <nav
      className="toolnav nav-padding nav-items-sm"
      role="navigation"
      aria-label="Tools"
    >
      <MultilevelNav data={data.toolNav} />

      <ul className="toolnav--desktop">
        {data.toolNav.map((item, i) => (
          <li
            key={i}
            className={item.children && item.children.length ? "has-sub" : ""}
          >
            <a href="#">
              {
                {
                  lang: <IconLanguage />,
                  tool: <IconTool />,
                }[item.icon]
              }
              <span>{item.name}</span>
            </a>
            {item.children && item.children.length ? (
              <ul>
                {item.children.map((children, i) => (
                  <li key={i}>
                    <a href={children.url || "#"}>{children.name} </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}

        <li className="toolnav--search">
          <a href="/search">
            <IconSearch className="desktop" />{" "}
            <span>{dictionary(dictionaryKeys.Search)}</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default ToolNav;
