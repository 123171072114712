import React from "react";
import VideoPlayerApp from "../VideoPlayer/VideoPlayerApp.jsx";
import { IconArrowSmall } from "../Common";

const VideoPromo = (props) => {
  return (
    <div className="videopromo">
      <VideoPlayerApp videoData={props} />
      <div className="videopromo--content">
        <div
          className="h2-content"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />

        <a href={props.linkUrl}>
          {props.linkText}
          <IconArrowSmall />
        </a>
      </div>
    </div>
  );
};

export default VideoPromo;
