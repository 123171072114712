import React from "react";
import ReactDom from "react-dom";

import PrimaryNav from "./PrimaryNavApp.jsx";

export default function renderPrimaryNav() {
  document.querySelectorAll(".js-primary-nav").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<PrimaryNav {...dataModel} />, mount);
  });
}
