import React from "react";
import ReactDom from "react-dom";

import AccordionCarouselApp from "./AccordionCarouselApp.jsx";

export default function renderAccordionCarousel(ancestor) {

  console.log("Render Accordion Carousel");

  if (!ancestor) {
    ancestor = document;
  }

  ancestor.querySelectorAll(".js-accordion-carousel-mount").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);  

    ReactDom.render(<AccordionCarouselApp {...dataModel} />, mount);
  });
}
