import React from "react";
import ReactDom from "react-dom";

import VideoPlayerApp from "../VideoPlayer/VideoPlayerApp.jsx";

export default function renderVideoPlayer() {
  document.querySelectorAll(".js-video-player").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<VideoPlayerApp {...dataModel} />, mount);
  });
}
