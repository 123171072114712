import React from "react";
import ReactDom from "react-dom";

import TabbedTextApp from "./TabbedTextApp.jsx";

export default function renderTabbedText() {
  document.querySelectorAll(".js-tabbed-text").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<TabbedTextApp {...dataModel} />, mount);
  });
}
