import React, { useState } from "react";

function AccordionItemTitle(props) {
  const isOpen = props.index === props.activeIndex;

  return (
    <div
      className={`contentaccordion--title ${isOpen ? "open" : ""}`}
      onClick={() => props.onClick(props.index)}
    >
      <h3>{props.title}</h3>

      {isOpen && (
        <div className="contentaccordion--content-mobile rich-content">
          <div dangerouslySetInnerHTML={{ __html: props.content.text }} />
        </div>
      )}
    </div>
  );
}

function AccordionItemContent(props) {
  const isOpen = props.index === props.activeIndex;

  return (
    <>
      {isOpen && (
        <div className="contentaccordion--content rich-content">
          <h3 className="h3-alt-lg">{props.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: props.content.text }} />
        </div>
      )}
    </>
  );
}

function AccordionItemImage(props) {
  const isOpen = props.index === props.activeIndex;

  return (
    <>
      {isOpen && (
        <div className="contentaccordion--image vertical-2">
          <img src={props.content.image} alt={props.content.alt} />
        </div>
      )}
    </>
  );
}

function AccordionList(props) {
  return (
    <div className="contentaccordion--list">
      {props.items.map((item, index) => (
        <AccordionItemTitle
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          activeIndex={props.activeIndex}
          onClick={props.onClick}
        />
      ))}
    </div>
  );
}

function ContentAccordion(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemToggle = (index) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? prevActiveIndex : index
    );
  };

  return (
    <div className="contentaccordion">
      <div className="contentaccordion--list-image">
        <div className="contentaccordion--list-wrapper">
          <AccordionList
            items={props.items}
            onClick={handleItemToggle}
            activeIndex={activeIndex}
          />
        </div>
        {props.items.map((item, index) => (
          <AccordionItemImage
            key={index}
            index={index}
            content={item.content}
            activeIndex={activeIndex}
          />
        ))}
      </div>
      <div className="contentaccordion--wrapper">
        <AccordionList
          items={props.items}
          onClick={handleItemToggle}
          activeIndex={activeIndex}
        />

        {props.items.map((item, index) => (
          <AccordionItemContent
            key={index}
            index={index}
            title={item.title}
            content={item.content}
            activeIndex={activeIndex}
          />
        ))}
      </div>
    </div>
  );
}

export default ContentAccordion;
