import React from "react";
import ReactDom from "react-dom";

import FeatureCarouselApp from "./FeatureCarouselApp.jsx";

export default function renderFeatureCarousel() {
  document.querySelectorAll(".js-feature-carousel").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<FeatureCarouselApp {...dataModel} />, mount);
  });
}
