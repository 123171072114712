import React from "react";
import ReactDom from "react-dom";

import LocationsMapApp from "./LocationsMapApp.jsx";

export default function renderLocationsMap() {
  document.querySelectorAll(".js-locations-map").forEach((mount) => {
    const dataModel = JSON.parse(mount.dataset.model);

    ReactDom.render(<LocationsMapApp {...dataModel} />, mount);
  });
}
