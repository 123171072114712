import React, { useState, useRef } from "react";
import useClickOutside from "../Common/Hooks/useClickOutside.jsx";
import CtaNav from "../CtaNav/CtaNav.jsx";
import Logo from "../Logo/Logo.jsx";
import MainNav from "../MainNav/MainNav.jsx";
import MobileNav from "../MobileNav/MobileNav.jsx";
import ToolNav from "../ToolNav/ToolNav.jsx";

const PrimaryNav = ({ primaryData, toolData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const navRef = useRef(null);
  const clickedOutside = useClickOutside(navRef);
  clickedOutside && isOpen && handleClick();

  return (
    <>
      <header ref={navRef} className="primarynav">
        <div className="primarynav--wrapper">
          <Logo logo={primaryData.logo} />
          <div className="primarynav--nav-container">
            <MainNav
              data={primaryData}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
            />
            <ToolNav data={toolData} />
            <CtaNav link={primaryData.connectLink} />
          </div>
          <MobileNav
            primaryData={primaryData}
            toolData={toolData}
            handleClick={handleClick}
            isOpen={isOpen}
          />
        </div>
      </header>
      <div
        className={`nav-overlay ${
          isHovered || isFocused || isOpen ? "show" : ""
        }`}
      ></div>
    </>
  );
};

export default PrimaryNav;
