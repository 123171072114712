import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import mapStyles from "./MapStyles";

const Map = ({ zoom, center, customClass, children, draggable }) => {
  var googleMapsApiKey = document.body.getAttribute("data-googleMapsApiKey");
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey:
      googleMapsApiKey ?? process.env.STORYBOOK_GOOGLE_MAPS_API_KEY ?? "",
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          zoom={zoom}
          center={center}
          draggable={draggable}
          mapContainerClassName={customClass}
          options={{ styles: mapStyles, disableDefaultUI: true }}  
        >
          {children}
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
};

export default Map;
