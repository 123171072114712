import React, { useState, useEffect } from "react";
import lateRender from "../../index";

function AccordionItemTitle(props) {
  const isOpen = props.index === props.activeIndex;

  return (
    <button
      className={`accordiontabs--title ${isOpen ? "open" : ""}`}
      onClick={() => props.onClick(props.index)}
    >
      {props.title}
    </button>
  );
}

function AccordionItemContent(props) {
  const isOpen = props.index === props.activeIndex;

  return (
    <>
      {isOpen && (
        <div className="accordiontabs--content">
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      )}
    </>
  );
}

function AccordionList(props) {
  return (
    <div className="accordiontabs--list">
      {props.items.map((item, index) => (
        <AccordionItemTitle
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          activeIndex={props.activeIndex}
          onClick={props.onClick}
        />
      ))}
    </div>
  );
}

function AccordionTabs(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemToggle = (index) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? prevActiveIndex : index,
    );
  };

  //after tab changes are rendered (when activeIndex changes), render any components that could appear on tabs.
  useEffect(() => {
    document.querySelectorAll(".js-accordion-tabs").forEach((mount) => {
      lateRender(mount);
    });
  }, [activeIndex]);

  return (
    <div className="accordiontabs">
      <div className="accordiontabs--list-wrapper">
        <AccordionList
          items={props.items}
          onClick={handleItemToggle}
          activeIndex={activeIndex}
        />
      </div>
      <div className="accordiontabs--wrapper">
        {props.items.map((item, index) => (
          <AccordionItemContent
            key={index}
            index={index}
            title={item.title}
            content={item.content}
            activeIndex={activeIndex}
          />
        ))}
      </div>
    </div>
  );
}

export default AccordionTabs;
