import React from "react";
import { IconArrowSmall } from "../Common/index.js";

const NavPromo = ({ data }) => {
  return (
    <>
      {data && (
        <div className="subnav--promo">
          {
            data.title && 
            (<a href={data.url} className="link-alt-2">
              {data.title}
              <IconArrowSmall fill={"var(--midnight-blue)"} />
            </a>
          )}
          
          <img src={data.image} alt={data.alt} />
          <p>{data.text}</p>

          {
            data.linkText &&         
           (<a href={data.linkUrl || "#"} className="link-alt">
              {data.linkText}
              <IconArrowSmall fill={"var(--midnight-blue)"} />  
            </a>
          )}
        </div>
      )}
    </>
  );
};

export default NavPromo;
