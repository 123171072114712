import React, { useRef } from "react";
import useTallestNodeValue from "../Common/Hooks/useTallestNodeHeight.jsx";
import { IconArrowSmall } from "../Common/index.js";
import NavPromo from "../NavPromo/NavPromo.jsx";

const SubNav = ({ subItems }) => {
  const subSub = useRef(null);
  const tallestNodeValue = useTallestNodeValue(subSub, ".subnav--child");
  const subNavHeight = tallestNodeValue === 0 ? 590 : tallestNodeValue;

  return (
    <div className="subnav">
      <div className="subnav--wrapper">
        <div
          className="subnav--nav"
          style={{ height: subNavHeight + "px", minHeight: "425px" }}
        >
          <ul className="subnav--parent" ref={subSub}>
            {subItems.map((items, index) => {
              return (
                <li key={index} className={items.isDefault ? "default" : ""}>
                  <a href={items.url}>{items.name}</a>
                  <IconArrowSmall fill={"var(--midnight-blue)"} />
                  {items.children && (
                    <ul className="subnav--child">
                      {items.children.map(
                        (item) =>
                          item.name && (
                            <li key={item.id}>
                              <div>
                                <span>
                                  <img src={item.icon} alt={item.alt} />
                                </span>
                                <a href={item.url}>{item.name}</a>
                                {item.arrow && (
                                  <IconArrowSmall
                                    fill={"var(--midnight-blue)"}
                                  />
                                )}
                              </div>
                              <p>{item.text}</p>
                            </li>
                          )
                      )}
                      {items?.children[0]?.promoData && (
                        <li className="subnav--promo-desktop">
                          <NavPromo data={items.children[0].promoData} />
                        </li>
                      )}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="subnav--child--container"></div>
        </div>
      </div>
    </div>
  );
};

export default SubNav;
